import { makeStyles } from "@material-ui/core/styles";
import {font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  numbers: {
    display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    flexDirection: 'column',
    backgroundColor: '#2e041c',
    [theme.breakpoints.down('sm')]: {
      fontSize: font.nomalSize__mobile,
    },
  },

  number_title: {
    display:'flex',
    justifyContent:'flex-start',
    alignItems:'flex-end',
    height: '50px',
    width:'100%',
    [theme.breakpoints.down('sm')]: {
      height: '40px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '30px',
    },
  },

  header_title: {
    '& span': {
      fontSize: font.largeSize,
      [theme.breakpoints.down('sm')]: {
        fontSize: font.largeSize__mobile
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: font.bigSize__mobile
      }
    }
  },
  imageTop: {
    height: '100%',
    width: 'auto'
  },

  number__body: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    justifyContent:'center',
    alignItems:'center',
    flexDirection: 'row',
    padding: '10px 0px 10px 0px'
  },

  number__body_background: {
    position: 'absolute',
    display: 'flex',
    width: '100%',
    height: '100%',
    left: '0',
    top: '0',
    opacity: '0.3',
    zIndex: '0',
  },  

  number_content: {
    // boxSizing: 'content-box',
    borderRadius: '5px',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1px',
    margin: '3px',
    width: '35px',
    height: '50px',
    fontSize: '28px',
    backgroundColor: '#de5c04',
    fontFamily: font.boldFamily,
    zIndex: '1',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '4px',
      fontSize: '24px',
      width: '35px',
      height: '43px',
      margin: '2px',
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: '2px',
      fontSize: '5vw',
      width: '6vw',
      height: '7.5vw',
      margin: '1px',
    }
    // [theme.breakpoints.down('sm')]: {
    //   borderRadius: '4px',
    //   fontSize: '20px',
    //   width: '28px',
    //   height: '35px',
    //   margin: '1px',
    // },
    // [theme.breakpoints.down('xs')]: {
    //   borderRadius: '2px',
    //   fontSize: '3vw',
    //   width: '3.8vw',
    //   height: '5vw',
    //   margin: '1px',
    // }
  }
}));
