import React, { useState, useEffect, useContext } from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Background, RouterContext } from 'context/RouterContext';
import ChangeLanguage from './components/ChangeLanguage';
import Home from 'pages/Home';
import HowToPlay from 'pages/HowToPlay';
import AboutUs from 'pages/AboutUs';
import Menu from './shared/Menu';
import Footer from './shared/Footer';
import LogoCompany from './pages/Home/LogoCompany';
import { StyledContainer } from "config/styles";
import { useStyles } from './AppStyles';
import { Hidden } from '@material-ui/core';
import { get } from 'lodash';
import { AuthContext } from 'context/AuthContext'
import MetaData from './services/MetaData';

const history = createBrowserHistory();

const Routes = () => {
	const classes = useStyles();
	const authContent = useContext(AuthContext);
	const [background, setBackground] = useState(Background.home);
	const [showTimer, setShowTimer] = useState(true);
	const [resultHomeData, setResultHomeData] = useState(authContent.resultHomeData);
	const [request, setRequest] = useState({})

	useEffect(() => {
		authContent.requestAuth().then((res) => {
			const isSuccess = get(res, 'data.success', false);
			if (isSuccess) {
				const data = get(res, 'data.data', {});
				authContent.dataList = data;
				const hData = authContent.mapDataHomePage(data)
				setResultHomeData(hData)
			}
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [request]);

	useEffect(()=> {
		const pathName = window.location.pathname;
		if (pathName.length > 2) {
			setShowTimer(false)
		} else {
			setShowTimer(true)
		}

		history.listen((location)=> {
			const pathName = location.pathname;
			if (pathName.length > 2) {
				setShowTimer(false)
			} else {
				setShowTimer(true);
				setRequest({});
			}
		})
	}, [])

	return (
		<RouterContext.Provider value={{ background: background, setBackground: setBackground }}>
			<Router history={history}>
				<MetaData />
				<div
					className={classes.Content}
					style={{ backgroundImage: `url(${background.src})` }}>
					<StyledContainer>
						<Hidden mdUp>
							<br />
							<ChangeLanguage />
						</Hidden>
						<Hidden smDown>
							<Menu />
						</Hidden>
						<LogoCompany isHomePage={showTimer} data={resultHomeData.timeNextDraw} />
						<br />
						<Hidden mdUp>
							<Menu />
						</Hidden>
						<Switch>
							<Route exact path='/' name='Home' component={Home} />
							<Route exact path='/how-to-play' name='how to play' component={HowToPlay} />
							<Route exact path='/about-us' name='About Us' component={AboutUs} />
							<Route path="*">
								<Redirect to="/" />
							</Route>
						</Switch>
					</StyledContainer>
				</div>
				<Footer />
			</Router>
		</RouterContext.Provider>
	);
}

export default Routes;
