const chinese = {
  translations: {
    "AboutUs": "About Us",
    "Date": "Date",
    "FooterCopyright": "COPYRIGHT © 2011 [company_name]. ALL RIGHTS RESERVED.",
    "Home": "Home",
    
    //end error message
    "Results": 'Results',
    'DrawNo': 'Draw No.',
    'AboutUsContent': `Established in 2019 as the official organizer of the Shanghai government's lottery game, Shanghai Lottery aims to create a fair, safe and comfortable lottery game platform for the entire People's Republic of China, especially Shanghai residents.
    
Shanghai Lottery is the only official organizer of lottery games approved by the central government and associated with China Welfare Lottery, China Sports Lottery, Hong Kong Jockey Club & Macau Game Slots.`,
    'Sun': 'Sunday',
    'Mon': 'Monday',
    'Tue': 'Tuesday',
    'Wed': 'Wednesday',
    'Thu': 'Thursday',
    'Fri': 'Friday',
    'Sat': 'Saturday',
    'No': 'No',
    'Day': 'Day', 
    'Result': 'Result', 
    'Prize': 'Prize',
    'HowToPlay': 'How to Play',
    'DrawResults': 'DRAW RESULTS',
    'CompanyName': 'SHANGHAI',
    'LastestResult': 'LATEST RESULT',
    'FooterText1': 'All Times Displayed In China Standar Time (CST is UTC+8) Unless Stated Otherwise. Please Note: Pricing On ShanghaiPools.net Differs From That Found At Traditional Retail Lottery Agencies.',
    'FooterText2': 'Deposit Funds Into Your Player Account Via The Following Methods:',
    'HowToPlayContent': `
1. You must be 18 years old or older to buy Lottery tickets and claim prizes.

2. Players are responsible for checking their tickets before leaving the Retailer location.

3. Pick out a lotto ticket at your grocery store or convenience store. The lottery stands usually have little pencils to help you to pick your numbers out on your lottery card.

4. A ticket is void if stolen, unissued, mutilated, illegible, tampered with or altered in any way, defective or incomplete.

5. The Lottery company is not responsible for lost or stolen tickets.

6. You must fill in your name, address and phone number on the back of your winning ticket before you file a prize claim.

7. All prizes must be claimed within 6 months after the drawing date.`

 }
};

export default chinese;
