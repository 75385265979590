import React from 'react';
import TableNumber from 'components/Numbers';
import { useStyles } from './styles';
import Image from 'react-image-webp';
import { useTranslation } from 'react-i18next';

import Top1 from 'assets/img/home/ic_cup.png';
import Top1Wepb from 'assets/imgWebp/ic_cup.webp';

import './styles.css'

const TableTopThree = ({data}) => {
  const classes = useStyles()
  const {t} = useTranslation()
  
  const renderTitle = () => {
    return (
      <div className={classes.winner_title}>
          <div className={classes.contanner__image}>
            <Image className={classes.image_title} alt='button prev' src={Top1} webp={Top1Wepb} />
        </div>
        <div className={classes.title_container}>
          <span className='radient-test'>
            {t('LastestResult')}
          </span>
          </div>
      </div>
    )
  }

  return <div className={classes.winners}>
      {renderTitle()}
      <div className={classes.winners__table__wrapper}>
        {
          data.map((item, index)=> {
            return <div key={index} className={classes.winners__table}>
                <TableNumber data={item} index={index} />
            </div>
          })
        }
      </div>
  </div>
};

export default TableTopThree;
