import { makeStyles } from "@material-ui/core/styles";
import { font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  container_page: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'flex-start',
    minHeight: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      // backgroundColor: color.mainBackground
    }
  },
  multi_prize_container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    marginBottom: '15px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
    '& span': {
      padding: '0 0 0 15px',
      [theme.breakpoints.down('sm')]: {
        padding: '0 0 0 10px',
        fontSize: font.nomalSize__mobile,
      },
    },
    '& :nth-child(1)': {
      color: '#fff'  
    },
    '& :nth-child(2)': {
      color: '#1ab50d'  
    },

    '& :nth-child(3)': {
      color: '#ffbb02'
    },
  },
  menus: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-around',
    flexDirection: 'column',
    flexFlow: 'column',
    alignItems: 'center',
    width: '200px',
    height: '490px', 
    marginRight: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginRight: '0',
      flexDirection: 'row',
      height: '40px', 
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: '0',
      width: '100%',
      height: '28px', 
    }
  },

  content: {
    display: 'inline-block',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      
    },
    [theme.breakpoints.down('xs')]: {
      // width: 'calc(100% -120px)',
    }
  },

  menuBackground: {
    position: 'absolute',
    top: '0',
    left: '0',
    display: 'flex',
    width: '100%',
    height: '100%',
    backgroundColor: '#000',
    opacity: '0.4',
    zIndex: '0',
    borderRadius: '5px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0',
      backgroundColor: '#640547',
      opacity: '1',
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: '0',
      backgroundColor: '#640547',
      opacity: '1',
    }
  },

  menuItem : {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontSize: font.nomalSize,
    width: '100%',
    padding: '15px 5px 15px 20px',
    cursor: 'pointer',
    zIndex: '1',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      padding: '10px 10px 10px 10px',
      fontSize: font.nomalSize__mobile,
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      padding: '10px 2px 10px 2px',
      fontSize: '2.5vw',
    }
  },
  menuItemSelected: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontSize: font.nomalSize,
    width: '100%',
    // height: '100%',
    cursor: 'pointer',
    zIndex: '1',
    padding: '15px 5px 15px 20px',
    backgroundImage: 'linear-gradient(to right, #9f0020, #9f255d)',
    backgroundColor: '#99086f',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      height: '100%',
      padding: '0px 10px 0px 10px',
      backgroundImage: 'none',
      fontSize: font.nomalSize__mobile,
      backgroundColor: '#99086f',
      borderBottom: '2px solid #f39615',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      height: '100%',
      padding: '0px 2px 0px 2px',
      backgroundImage: 'none',
      fontSize: '2.5vw',
      backgroundColor: '#99086f',
      borderBottom: '2px solid #f39615',
    }    
  },
  dialog_result: {
    display: 'flex',
    position: 'absolute',
    right: '0',
    fontSize: '30px',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection:'column'
  }
}));
