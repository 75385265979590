import { makeStyles } from "@material-ui/core/styles";
import { color } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  App: {
    backgroundColor: color.mainBackground,
    color: color.white,
  },
  Content: {
    position: 'relative',
    minHeight: `calc(100vh - 165px)`,
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'scroll',
    backgroundSize: "100% auto",
    backgroundColor: '#47032c',
    animation: 'fadeIn ease-in .5s',
    transition: 'all .5s ease-in-out',
    [theme.breakpoints.down('sm')]: {
      backgroundSize: '140% auto',
      minHeight: `calc(100vh - 185px)`,
    },
    [theme.breakpoints.down('xs')]: {
      backgroundSize: '150% auto',
      minHeight: `calc(100vh - 155px)`,
    },
  }
}));
