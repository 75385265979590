import React, {useState, useRef, useEffect} from "react";
import Image from 'react-image-webp';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

import label from 'assets/img/home/time_box.png';
import labelWepb from 'assets/imgWebp/time_box.webp';
import clamp from 'assets/img/common/clamp.png';
import clampWepb from 'assets/imgWebp/clamp.webp';


function useInterval(callback, delay) {
	const savedCallback = useRef();
  
	// Remember the latest callback.
	useEffect(() => {
	  savedCallback.current = callback;
	}, [callback]);
  
	// Set up the interval.
	useEffect(() => {
	  function tick() {
		savedCallback.current();
	  }
	  if (delay !== null) {
		let id = setInterval(tick, delay);
		return () => clearInterval(id);
	  }
	}, [delay]);
}
  
const TimerView = ({data = {}}) => {
  const classes = useStyles()
  const [dataTimer, setDataTimer] = useState({})
  const [timer, setTimer] = useState({
	seconds: '00' ,
	hours: '00',
	minutes: '00',
	canCowntdow: false
  })

  const {t} = useTranslation();
  const handleTimer = ()=> {
	  let timeUntilNextResultSeconds =  dataTimer.timeUntilNextResultSeconds
	  if (timeUntilNextResultSeconds && timeUntilNextResultSeconds > 0) {
			timeUntilNextResultSeconds -= 1
			let hours = parseInt(timeUntilNextResultSeconds / (60 * 60 ), 10)
			timeUntilNextResultSeconds -= (hours * 60 * 60)
			let minutes = parseInt(timeUntilNextResultSeconds / 60, 10)
			timeUntilNextResultSeconds -= minutes * 60
			let seconds = parseInt(timeUntilNextResultSeconds , 10)
	
			minutes = minutes < 10 ? "0" + minutes : minutes;
			seconds = seconds < 10 ? "0" + seconds : seconds;
			hours = hours < 10 ? "0" + hours : hours;
	
			setDataTimer({
				...data,
				timeUntilNextResultSeconds: dataTimer.timeUntilNextResultSeconds - 1
			})
	
			setTimer({
				seconds,
				hours,
				minutes,
				canCowntdow: true
			})
		} else {

			if (timer.canCowntdow) {
				window.location.reload();
			}
			
			setTimer({
				seconds: '00' ,
				hours: '00',
				minutes: '00',
				canCowntdow: false
			})

		}
  }
	
  	useInterval(handleTimer, 1000);
	
	useEffect(() => {
		setDataTimer(data);
	}, [data])

  return (
	  <div className={classes.container_page} style={{ animation: 'fadeIn ease-in .1s' }}>
		  	<Image alt='logo' src={clamp} webp={clampWepb} className={classes.imageClamp} />
      		<div className={classes.timer_title}>
        		<span>
          			{t('DrawResults')}</span>
      		</div>
		  	<div className={classes.content}>
			  	<div className={classes.timer__bg}>
				  <Image alt='bet winner' src={label} webp={labelWepb} className={classes.imageItemFlag} />
				  <span>{timer.hours}</span>
				</div>
				<div className={classes.timer__bg__point}>
					<span>:</span>
				</div>
			  	<div className={classes.timer__bg}>
			  		<Image alt='bet winner' src={label} webp={labelWepb} className={classes.imageItemFlag} />
					<span>{timer.minutes}</span>
				</div>
				<div className={classes.timer__bg__point}>
					<span>:</span>
				</div>
			  	<div className={classes.timer__bg}>
			  		<Image alt='bet winner' src={label} webp={labelWepb} className={classes.imageItemFlag} />
					<span>{timer.seconds}</span>
				</div>
		  </div>
		  <div className={classes.date_content}>
		  	<span>{dataTimer.date}</span>
		  </div>
    </div>
  )
};

export default TimerView
