import { makeStyles } from "@material-ui/core/styles";
import { font } from "../../../config/styles";
export const useStyles = makeStyles((theme) => ({
  table: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    marginBottom:'2vh',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5vw',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.8vw',
    }
  },

  table__header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '10px',
    width: '100%',
    '& div': {
      height: '60px',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        height: '50px'
      },
      [theme.breakpoints.down('xs')]: {
        height: '40px'
      }
      
    },
    '& th:nth-child(1)': {
      borderTopLeftRadius: '6px',
    },
    '& th:nth-child(2)': {
      borderTopRightRadius: '6px',
    },
  },

  table__header_1: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    backgroundColor:'#1c0414',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    [theme.breakpoints.down('sm')]: {
      borderTopLeftRadius: '0px',
    borderTopRightRadius: '0px',
    },
    '& :nth-child(1)': {
      paddingLeft: '10px'
    },
    '& div': {
      textAlign: 'center',
      height: '40px',
      // paddingLeft: '10px' 
    },

  },

  row_header_table: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40px',
    fontFamily: font.boldFamily
  },

  table__body_1: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& div': {
      textAlign: 'center'
    },
  },

  row_table: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    color: 'white',
    zIndex: '1',
  },

  row_table_sub_top: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: 'white',
    zIndex: '1',
  },

  row_table_sub_bottom: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: 'white',
    zIndex: '1',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.0vw',
      color: 'red'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.2vw',
      color: 'red'
    }
  },

  row_table_break: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    color: 'white',
  },

  row_circle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px',
    width: '30px',
    height: '40px',
    maxHeight: '30xp',
    backgroundColor:'black',
    color: 'white',
    margin:'3px',
    boxSizing: 'content-box',
    fontFamily: font.boldFamily,
    [theme.breakpoints.down('xs')]: {
      width: '5.5vw',
      height: '6.2vw',
      borderRadius: '3px',
    }
  },

  body_bg_1: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50px',
    borderBottom: '1px solid black',
    [theme.breakpoints.down('sm')]: {
      height: '50px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '40px',
    }
  },

  body_bg_2: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-round',
    height: '50px',
    borderStyle: 'inset',
    borderBottom: '1px solid black',
    borderTop: '0px solid black',
    borderLeft: '0px solid black',
    borderRight: '0px solid black',
    backgroundColor:'#490b32',
    [theme.breakpoints.down('sm')]: {
      height: '50px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '40px',
    },
  },

  body__background: {
    position:'absolute',
    left: '0',
    top: '0',
    display: 'flex',
    width: '100%',
    height: 'calc(100% + 1px)',
    backgroundColor: '#2c041e',
    borderBottom: '1px solid black',
    // opacity: '0.3',
    zIndex: '0'

  },
  body__background_2: {
    position:'absolute',
    left: '0',
    top: '0',
    display: 'flex',
    width: '100%',
    height: 'calc(100% + 1px)',
    backgroundColor: '#490b32',
    borderBottom: '1px solid black',
    // opacity: '0.3',
    zIndex: '0'

  }

}));
