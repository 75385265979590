import React, { useState, useEffect } from 'react';
import { map, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

const DialogDetail = ({ data, index }) => {
	const props = { index }
	const {t} = useTranslation()
	const classes = useStyles(props);
	const [mapDataDialog, setMapDataDialog] = useState({
		prizeList: [],
		singlePrizes: []
	})

	useEffect(()=> {
		const mData = mapDataResult(data)
		setMapDataDialog(mData)
	}, [data])

	const mapDataResult = function (data) {
		const results = get(data, 'result_detail', {})
		const periodNext = get(results, 'period', 'N/A')
		const periodDate = get(results, 'period_date', 'N/A')
		const prizeListData = get(results, 'prizeList', [])
		const singlePrizeListData = get(results, 'singlePrizeList', [])
		const singlePrizes = map(singlePrizeListData, (item, index) => {
			return {
				...item,
				index: index + 1,
				numbers: item.result
			}
		})

		const prizeList = map(prizeListData, (item, index) => {
			return {
				...item,
				id: index,
				resuls: item.result.map((ressultString) => {
					return ressultString
				})
			}
		})

		return {
			periodDate,
			period: periodNext,
			singlePrizes,
			prizeList
		}
	}

	const renderContent = () => {
		const {singlePrizes, prizeList} = mapDataDialog
		return (
			<div className={classes.winners__table__wrapper}>
				<div className={classes.winners__table}>
					{map(singlePrizes, (item, index)=>{
						return <div key={index} className={classes.rowSingle}> 
							<pre className={classes.bold__content}>{item.name}</pre>
							<pre>{item.result}</pre>
						</div>
					})}
					<hr />
					{map(prizeList, (multpPrize, i)=>{
						return <div key={i} className={classes.rowMulti}>
							<span className={classes.bold__content}>{multpPrize.name}</span>
							<div className={classes.rowMultiItenm}>
							{map(multpPrize.resuls, (number, j)=>{
								return <span key={j} >{number}</span>})}
							</div>
						</div>
					})}
				</div>
			</div>
		)
	};

	const renderTitle = () => {
		const drawTitle = `${t('DrawNo')}${mapDataDialog.period}`
		const drawDate = `${mapDataDialog.periodDate}`
		return (
			<div className={classes.winner_title}>
				<span>
					{drawTitle}
				</span>
				<span>
					{drawDate}
				</span>
			</div>
		)
	}

	return <div className={classes.winners}>
		{renderTitle()}
		{renderContent()}
	</div>
};

export default DialogDetail;
