import React from "react";
import Image from 'react-image-webp';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

import clamp from 'assets/img/common/How_to_play.png';
import clampWepb from 'assets/imgWebp/How_to_play.webp';

const HowToPlay = () => {
  const classes = useStyles()
  const {t} = useTranslation();
  
  return (
    <div className={classes.container_page} style={{animation: 'fadeIn ease-in .1s'}}>
      <div className={classes.howtoplay_title}>
          <span>
          {t('HowToPlay')}</span>
      </div>
      <div className={classes.content}>
        <span>
          
          <pre className={classes.content_center}>
            {t('HowToPlayContent')}
          </pre>
          
        </span>
      </div>
      <div className={classes.content__image}>
        <Image alt='bet winner' src={clamp} webp={clampWepb} className={classes.imageItemFlag} />
      </div>
      <br />
    </div>
  )
};

export default HowToPlay
