import { makeStyles } from "@material-ui/core/styles";

import { font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  container_page: {
    margin: '0 100px 0 100px',
    [theme.breakpoints.down('sm')]: {
      margin: '0 10px 0 10px',
    },
    [theme.breakpoints.down('xs')]: {
			margin: '0 5px 0 5px',
		}
  },
  howtoplay_title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textTransform: 'uppercase',
    '& span': {
      padding:'5px',
      fontSize: '40px',
      fontFamily: font.boldFamily,
      [theme.breakpoints.down('sm')]: {
        fontSize: '4.5vw',
      }
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
      margin: '0 5px 0 5px',
    }
  },

  lable_image: {
    height: '12px',
    width: 'auto',
    margin:'2px 2px 0px 0px'
  },

  content: {
    display: 'flex',
    margin: '1px',
    color: 'white',
    fontSize: font.nomalSize,
    '& span': {
      // backgroundColor: 'white',
      margin: '1px',
      textAlign: 'left',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: font.nomalSize__mobile,
      margin: '0 5px 0 5px',
    }
  },
  content_center: {
    display: 'flex',
    justifyContent:'flex-start',
    alignItems:'flex-start',
    fontSize: font.nomalSize,
    margin: '10px',
    whiteSpace: 'pre-wrap',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5vw',
      margin: '0 5px 0 5px',
    }
  },
  content__image: {
    display: 'flex',
    justifyContent:'center',
    alignItems:'flex-start',
    margin: '1px',
    [theme.breakpoints.down('sm')]: {
      fontSize: font.nomalSize__mobile,
      margin: '0 5px 0 5px',
    }
  },
  imageItemFlag: {
    height: '300px',
		margin: '5px',
		[theme.breakpoints.down('sm')]: {
			height: '200px',
		},
		[theme.breakpoints.down('xs')]: {
			height: '25vw',
		}
  }
}));
