import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ChangeLanguage from './../../components/ChangeLanguage';
import { AuthContext } from 'context/AuthContext'
import { useStyles } from "./styles"
import { Hidden } from '@material-ui/core';

const Menu = () => {
	const classes = useStyles();
	const authContext = useContext(AuthContext);
	const { t } = useTranslation();
	const handleClick = () => {
		if (authContext.homeFunc) {
			authContext.homeFunc();
		}
	}
	return <div className={classes.menu__list}>
		<Hidden smDown>
			<div className={classes.menu__item_language}>
				<ChangeLanguage />
			</div>
		</Hidden>
		<div className={classes.menu__routers}>
			<div className={classes.menu__item}>
				<NavLink exact onClick={handleClick} className={classes.menu__item_link} activeClassName={classes.menu__item_selected} to={'/'}>{t('Home')}
				</NavLink>
			</div>

			<div className={classes.menu__item}>
				<NavLink exact className={classes.menu__item_link} activeClassName={classes.menu__item_selected} to={'/how-to-play'}>{t('HowToPlay')}
				</NavLink>
			</div>

			<div className={classes.menu__item}>
				<NavLink exact className={classes.menu__item_link} activeClassName={classes.menu__item_selected} to={'/about-us'}>{t('AboutUs')}
				</NavLink>
			</div>
		</div>
	</div>
};

export default Menu
