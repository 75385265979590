import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from 'context/AuthContext'
import { useTranslation } from 'react-i18next';
import { map, get } from 'lodash';
import RTable from './../Results/RTable';
import TableTopThreeView from './../Home/TableTopThree'
import TableWinners from './../Home/TableWinners'
import DialogDetail from './../Home/DialogDetail'
import {stringToArray} from './../../utils'
import { Hidden } from '@material-ui/core';
import { useStyles } from './styles';
import Api from 'services/Api';

const Home = (props) => {
	const classes = useStyles()
	const authContent = useContext(AuthContext);
	const { resultHomeData } = authContent
	const [daySelected, setDaySelected] = useState('')
	const [selectIndex, setSelectIndex] = useState(-1)
	const [dataDayOfWeek, setDataDayOfWeek] = useState(null)
	const [dataSelectToShow, setDataSelectToShow] = useState(null)
	const [mapDataDefault, setMapDataDefault] = useState({
		drawTime: {},
		prizeList: [],
		singlePrizes: []
	})
	const [dataMap, setDataMap] = useState({
		header: [],
		body: []
	})
	
	const {t} = useTranslation()
	useEffect(()=> {
		if (daySelected === '') {
			setMapDataDefault(resultHomeData);
		}

		authContent.homeFunc = () => {
			setDaySelected('')
		}
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resultHomeData.prizeList, t])

	const days = ['Mon','Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
	const header = [t('Day'), t('Date'), t('Result'), t('Prize')]
	const mapDataToTable = (data) => {
		const body = map(data, (resul, index) => {
			return [
				t(resul.dayOfWeek),
				resul.date,
				stringToArray(resul.result),
				resul.prize
			]
		})
		
		return {
			header,
			body
		}
	}

	const handleMouseMove = (index)=> {
        if (selectIndex === -1) {
            if (dataDayOfWeek && dataDayOfWeek.length > index && index >= 0) {
                const data = dataDayOfWeek[index]
                if (data !== dataSelectToShow) {
                    setDataSelectToShow(data);
                }
            }
        }
    }

    const handleMouseLeve = (index)=> {
        if (selectIndex === -1) {
            setDataSelectToShow(null);
        }
	}
	
	//============================//
	//
	//============================//
	const handleSelected = (select) => {
		if (select !== daySelected) {
			setDaySelected(select);
			setDataSelectToShow(null);
			setSelectIndex(-1);
			Api.requestPageResult(select).then((res) => {
				const isSuccess = get(res, 'data.success', false);
				if (isSuccess) {
					const data = get(res, 'data.data', {});
					const hData = mapDataToTable(data)
					setDataMap(hData)
					setDataDayOfWeek(data)
				} else {
					const hData = mapDataToTable([])
					setDataMap(hData)
				}
			});
		}
	}
	
	//============================//
	//
	//============================//
	const handleShowResultDialog = (index)=> {
		if (dataDayOfWeek && dataDayOfWeek.length > index && index >= 0) {
			const data = dataDayOfWeek[index]
			setDataSelectToShow(data);
		} else {
			setDataSelectToShow(null);
		}
		setSelectIndex(index);
	}

	const renderDialogDetailResult = ()=> {
		if (dataSelectToShow) {
			return <DialogDetail data={dataSelectToShow} />
		}
		return null
	}
	
	const renderFirstMultPrize = ()=> {
		if (mapDataDefault.prizeListFirst && mapDataDefault.prizeListFirst.length > 0) {
			return <>
			{
				map(mapDataDefault.prizeListFirst, (item, index) => {
					return <TableWinners key={index} index={index} data={item} />
				})
			}
			</>
		}
		return null;
	}

	const renderSecondMultPrize = ()=> {
		if (mapDataDefault.prizeListSecond && mapDataDefault.prizeListSecond.length > 0) {
			return <div className={classes.multi_prize_container}>
				{map(mapDataDefault.prizeListSecond, (item, index) => {
					if (item.resuls.length > 0) {
						return <span key={index}> {item.name}: {item.resuls[0]}</span>
					}
				})}
				<br />
			</div>
		}
		return <br />
	}
	const handleRenderResult = () => {
		if (daySelected === '') {
			return <>
				{renderFirstMultPrize()}
				{renderSecondMultPrize()}
				<TableTopThreeView data={mapDataDefault.singlePrizes} />
			</>
		} else {
			return <>
				<Hidden mdUp>
					<br />
				</Hidden>
				<RTable 
                    onClick={handleShowResultDialog} 
                    onMouseMove={handleMouseMove} 
                    onMouseLeave={handleMouseLeve} 
                    selectedIndex={selectIndex} 
                    style={{ width: '100%', }} 
                    data={dataMap} level={1} 
                />
				{renderDialogDetailResult()}
				<br />
			</>
		}
	}

	return(
		<div className={classes.container_page} style={{ animation: 'fadeIn ease-in .3s' }}>
			<Hidden smDown>
				<div className={classes.menus}>
					<div className={classes.menuBackground} />
					{
						days.map((day, index) =>
						{	
							let classSelected = classes.menuItem
							if (day === daySelected) {
								classSelected = classes.menuItemSelected
							}
							return <div key={index} className={classSelected} onClick={()=>handleSelected(day)}> {t(day)} </div>
						})
					}
				</div>
				<div className={classes.content}>
					{handleRenderResult()}
				</div>			
			</Hidden>
			<Hidden mdUp>
				<div className={classes.menus}>
					<div className={classes.menuBackground} />
					{
						days.map((day, index) =>
						{	
							let classSelected = classes.menuItem
							if (day === daySelected) {
								classSelected = classes.menuItemSelected
							}
							return <div key={index} className={classSelected} onClick={()=>handleSelected(day)}> {t(day)} </div>
						})
					}
				</div>
				{handleRenderResult()}
			</Hidden>
			<br />
		</div>
	)
};

export default Home
