import { makeStyles } from "@material-ui/core/styles";
import { layout, font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
	footer: {
		display: 'flex',
		justifyContent: 'center',
		height: layout.footerHeight,
		backgroundColor: '#1c0112',
		[theme.breakpoints.down('sm')]: {
			// height: layout.footerHeight__mobile,
			height: 'auto',
			padding: '1vw 0',
		}
	},
	footerClass: {
		display: 'flex',
		width: "100%",
		height: "100%",
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		textAlign: 'center',
	},

	footerLeft: {
		width: "100%",
		display: 'flex',
		alignItems: "center",
		justifyContent: 'center'
	},
	footerTextLeft: {
		display: 'flex',
		alignItems: "center",
		justifyContent: 'center',
		flexDirection: 'row',
		flexWrap: 'wrap',
		fontSize: font.smallSize,
		margin: '0px 20px 0 20px',
		[theme.breakpoints.down('sm')]: {
			fontSize: font.smallSize__mobile,
		}
	},

	footerRight: {
		width: "100%",
		display: 'flex',
		alignItems: "center",
		justifyContent: 'center',
		fontSize: font.smallSize,
		[theme.breakpoints.down('sm')]: {
			fontSize: '2vw',
			alignItems: "center",
		},
		'& span': {
			wordWrap: 'break-word',
			fontSize: font.nomalSize,
			marginLeft: '3px',
			marginRight: '3px',
			[theme.breakpoints.down('sm')]: {
				fontSize: '1.8vw',
			},
		}
	},

	footerDeposit: {
		marginTop: '10px'
	},
	
	imageItem: {
		height: '60px',
		margin: '5px',
		[theme.breakpoints.down('sm')]: {
			height: '55px',
			margin: '2px',
		},
		[theme.breakpoints.down('xs')]: {
			height: '8.5vw',
			margin: '1px',
		}
	},
	imageItemFlag: {
		height: '20px',
		margin: '5px',
		[theme.breakpoints.down('sm')]: {
			height: '20px',
		},
		[theme.breakpoints.down('xs')]: {
			height: '4vw',
		}
	}

}));
