import React from 'react';
import Image from 'react-image-webp';
import Logo from 'assets/img/home/logo.png';
import LogoWepb from 'assets/imgWebp/logo.webp';
import { useStyles } from './styles'
import TimerView from './../../TimerView';

const LogoCompany = ({isHomePage, data}) => {
  const classes = useStyles();
  let className = classes.containner;
  let classNameLogo = classes.img_logo
  
  if (isHomePage) {
    classNameLogo = classes.img_logo_home_page
    className = classes.containner_home;
  }
  const renderTimer = ()=> {
		if (isHomePage) {
			return <TimerView  data={data} />
		}
	}
  return (
    <>
      <div className={className}>
        <Image alt='logo' src={Logo} webp={LogoWepb} className={classNameLogo} />
        {renderTimer()}
      </div>
    </>
  )
};

export default LogoCompany
